<template>
	<screen class="donation-form">
		<div slot="title">
			<div
				:style="{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '10px'
				}"
			>
				<h1>Donation Form for Website Embed</h1>
			</div>
		</div>
		<div
			slot="body"
			v-if="!showNoNonprofitMessage"
			style="padding: 0px 25px"
		>
			<div style="font-size: 16px; color: #484a50 !important">
				<p style="font-size: 16px !important" class="mb-4">
					Give your customers multiple ways to support your selected
					nonprofit by adding our customizable donation form to your
					website. In 3 simple steps you can start processing one-time
					donations for your selected nonprofit that will enable
					people to donate larger amounts without having to making a
					purchase! To get started:
				</p>
				<ol>
					<li style="line-height: 2">
						<strong>Customize:</strong> Update your nonprofit,
						title, colors, and donation options. (Check the preview
						to be sure you like what you see!)
					</li>
					<li style="line-height: 2">
						<strong>Copy:</strong> Click on the "Embed Code" tab to
						see the embed code and copy it.
					</li>
					<li style="line-height: 2">
						<strong>Insert:</strong> Add the code to your website on
						a landing page, your homepage, or anywhere you'd like to
						give people the opportunity to donate.
					</li>
				</ol>
				<v-alert color="blue" style="color: white; margin-top: 20px">
					<strong>Important Note:</strong> The preview allows you to
					test the embed before publishing on a website. Use our test
					credit card to click through the whole transaction: 4242
					4242 4242 4242 12/26 555 12345.</v-alert
				>
			</div>
			<div class="mt-12" style="display: flex; flex-direction: row">
				<div class="donation-form-form" style="flex: 1">
					<v-form
						v-model="valid"
						@submit.prevent="onSubmit"
						ref="form"
					>
						<v-select
							:items="orgList"
							item-text="name"
							:item-value="v => v"
							label="Nonprofit Name"
							outlined
							@change="onSelect"
							:value="selectedInstitution"
						>
						</v-select>
						<v-text-field
							placeholder="Start Supporting"
							v-model="form.title"
							label="Header Title"
							outlined
							@input="onSubmit"
						></v-text-field>
						<v-checkbox
							style="margin-top: -15px !important"
							class="mt-0"
							v-model="form.show_header"
							@change="onSubmit"
							label="Display Header"
						></v-checkbox>
						<v-menu
							offset-x
							:close-on-content-click="false"
							min-width="0"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									width="100%"
									:color="form.primary_color || '#2553CD'"
									dark
									v-bind="attrs"
									v-on="on"
									style="margin-bottom: 30px"
								>
									Click to set Primary Color
								</v-btn>
							</template>
							<v-color-picker
								class="ma-0"
								dot-size="25"
								swatches-max-height="200"
								:value="form.primary_color || '#2553CD'"
								@input="onPrimaryColorInput"
								mode="hexa"
							></v-color-picker>
						</v-menu>

						<v-menu
							offset-x
							:close-on-content-click="false"
							min-width="0"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									width="100%"
									:color="form.secondary_color || '#4337A7'"
									dark
									v-bind="attrs"
									v-on="on"
									style="margin-bottom: 30px"
								>
									Click to set Secondary Color
								</v-btn>
							</template>
							<v-color-picker
								class="ma-0"
								dot-size="25"
								swatches-max-height="200"
								:value="form.secondary_color || '#4337A7'"
								@input="onSecondaryColorInput"
								mode="hexa"
							></v-color-picker>
						</v-menu>
						<v-text-field
							placeholder="10"
							:value="form.donation_option_1"
							label="Donation Option 1"
							outlined
							prefix="$"
							type="number"
							@input="onInput($event, 1)"
							:rules="[
								v =>
									parseInt(v) >= 5 ||
									'Donations must be at least $5'
							]"
						></v-text-field>
						<v-text-field
							placeholder="20"
							:value="form.donation_option_2"
							label="Donation Option 2"
							outlined
							prefix="$"
							type="number"
							@input="onInput($event, 2)"
							:rules="[
								v =>
									parseInt(v) >= 5 ||
									'Donations must be at least $5'
							]"
						></v-text-field>
						<v-text-field
							placeholder="30"
							:value="form.donation_option_3"
							label="Donation Option 3"
							outlined
							prefix="$"
							type="number"
							@input="onInput($event, 3)"
							:rules="[
								v =>
									parseInt(v) >= 5 ||
									'Donations must be at least $5'
							]"
						></v-text-field>

						<v-checkbox
							class="mt-0"
							v-model="form.display_other_amount"
							@change="onSubmit"
							label='Display "Other Amount" option'
						></v-checkbox>
					</v-form>
				</div>
				<div
					v-if="loading"
					style="flex: 2; display: flex; flex-direction: column; align-items: center; justify-content: center"
				>
					<div style="width: 50%; text-align: center;">
						{{ loadingText }}
					</div>
				</div>
				<div
					v-show="!loading"
					style="flex: 2; display: flex; flex-direction: column; align-items: center"
				>
					<v-tabs
						v-model="tabs"
						style="max-height: 48px !important; max-width: 480px"
					>
						<v-tab
							:style="{
								border: `1px solid #2553CD`
							}"
						>
							Preview
						</v-tab>
						<v-tab
							:style="{
								border: `1px solid #2553CD`
							}"
						>
							Embed Code
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="tabs">
						<v-tab-item>
							<div ref="embedRef">
								<div style="height: 50%">
									<roundup-donation-embed
										:styleoptions="
											JSON.stringify({
												minWidth: '480px',
												border: `solid 1px ${form.primary_color}`,
												boxShadow:
													'0px 2px 4px 0px #000000',
												background: 'white !important'
											})
										"
										type="merchant"
										:institution_uuid="
											activeOrgUUID ||
												this.merchant.institution.uuid
										"
										:primarycolor="form.primary_color"
										:secondarycolor="form.secondary_color"
										:showtopbar="form.show_header"
										:options="
											`[
							${form.donation_option_1},
							${form.donation_option_2},
							${form.donation_option_3}
						]`
										"
										:primarycta="form.title"
										:hidecustominput="
											form.display_other_amount
												? 'false'
												: 'true'
										"
									></roundup-donation-embed>
								</div>
							</div>
						</v-tab-item>
						<v-tab-item>
							<div
								:style="{
									border: `1px solid ${form.primary_color}`,
									height: '397px',
									overflowY: 'auto',
									overflowX: 'none',
									maxWidth: '480px',
									padding: '16px',
									userSelect: 'none',
									opacity: '.5',
									cursor: 'pointer'
								}"
								@click="
									copyEmbedCode(embedCodeContent, 'click')
								"
							>
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-on="on" v-bind="attrs">
											{{ embedCodeContent }}
										</div>
									</template>
									<span>
										{{
											clickCopied
												? 'Code Copied!'
												: 'Click to copy'
										}}</span
									>
								</v-tooltip>
							</div>
							<div
								style="display: flex; flex-direction: column;  align-items: center"
							>
								<RoundupButton
									:color="copied ? 'green' : 'primary'"
									@click="
										copyEmbedCode(
											embedCodeContent,
											'button'
										)
									"
									style="margin-top: 24px; margin-bottom: 16px;"
									:style="{
										color: 'white !important',
										minWidth: '178px !important'
									}"
								>
									{{
										copied
											? 'Code Copied!'
											: 'Copy Embed Code'
									}}
								</RoundupButton>
							</div>
						</v-tab-item>
					</v-tabs-items>
				</div>
			</div>
		</div>
		<div slot="body" v-else style="min-height: 80vh;">
			<div
				style="display: flex; flex-direction: row; justify-content: center; "
			>
				<v-alert color="blue" style="color: white; margin-top: 20px">
					Please activate a nonprofit on the Your Cause page in order
					to customize your donation form embed.</v-alert
				>
			</div>
		</div>
	</screen>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getUrlParams } from '../../../utilities'
import Screen from '../../ui/Screen.vue'
import UiLoader from '../../ui/Loader.vue'
import { analytics } from '../../../utilities/tracker'
export default {
	name: 'donation-form',
	props: [],
	components: {
		Screen,
		UiLoader
	},
	data() {
		return {
			form: {
				title: 'Donate Now',
				primary_color: '#2553CD',
				secondary_color: '#4337A7',
				donation_option_1: 25,
				donation_option_2: 50,
				donation_option_3: 100,
				display_other_amount: true,
				show_header: true
			},
			primaryColor: null,
			valid: false,
			error: false,
			success: false,
			loading: false,
			loadingText: 'Loading...',
			loadingEmbedObserver: null,
			tabs: 'Preview',
			copied: false,
			clickCopied: false,
			showNoNonprofitMessage: false,
			selectedInstitution: null
		}
	},
	computed: {
		...mapState({
			embed: ({ embedDetail }) => embedDetail.item,
			activeOrgUUID: ({ embedDetail }) => embedDetail.activeOrgUUID,
			merchant: ({ me }) => me.current.merchant
		}),
		...mapGetters(['me', 'institutionList']),
		orgList() {
			if (
				this.institutionList &&
				this.institutionList.active.length > 0
			) {
				return this.institutionList.list
					.map(e => e.institution)
					.sort((a, b) => (a.name > b.name ? 1 : -1))
			}

			if (this.merchant && this.merchant.institution) {
				return [this.merchant.institution]
			}

			return null
		},
		embedCodeContent() {
			const option_1 = this.form.donation_option_1
			const option_2 = this.form.donation_option_2
			const option_3 = this.form.donation_option_3
			return `<script>
	var link = document.createElement('link');
    link.rel="stylesheet"
    link.href = "https://s3.amazonaws.com/embed.roundupapp.com/prod/donation/live/css/roundup-donation-embed.css";
    document.head.appendChild(link);
<\/script>
\n<roundup-donation-embed
	institution_uuid="${this.merchant.institution.uuid}"
	primarycolor="${this.form.primary_color}"
	secondarycolor="${this.form.secondary_color}"
	hideCustomInput="${!this.form.display_other_amount}"
	showTopBar="${this.form.show_header}"
	options="[${option_1},${option_2},${option_3}]"
	primaryCTA="${this.form.title}"
	type="merchant"
/>
\n<script src="https://s3.amazonaws.com/embed.roundupapp.com/prod/donation/live/js/roundup-donation-embed.js"><\/script>`
		}
	},
	methods: {
		...mapActions([
			'getEmbedForm',
			'createOrUpdateEmbed',
			'listMyInstitutions'
		]),
		async copyEmbedCode(value, type = 'click') {
			this.copied = false
			this.clickCopied = false

			if (!navigator.clipboard) {
				// Clipboard API not available
				return
			}
			try {
				await navigator.clipboard.writeText(value)
				analytics.track('embed_code_copied', { ...this.form })
				if (type === 'click') {
					this.clickCopied = true
				} else {
					this.copied = true
				}
				setTimeout(() => {
					if (type === 'click') {
						this.clickCopied = false
					} else {
						this.copied = false
					}
				}, 2500).bind(this)
			} catch (err) {}
		},
		onInput(v, num) {
			if (!this.$refs.form.validate()) {
				return
			}
			this.form['donation_option_' + num] = v
			this.onSubmit()
		},
		async onSubmit() {
			try {
				await this.createOrUpdateEmbed(this.form)
				this.updateEmbedAttributes()
				this.success = 'Embed successfully updated!'
			} catch (e) {
				this.error = 'Oh no! Something went wrong. Please try again.'
			}
			setTimeout(() => {
				this.success = false
				this.error = false
			}, 5000)
		},
		async onSelect(v) {
			this.selectedInstitution = v
			await this.$store.commit('EMBED_DETAIL_SET_ACTIVE_ORG_UUID', v.uuid)
			await this.getEmbedForm()
			for (var key in this.$data.form) {
				this.$data.form[key] = this.embed[key]
				if (key === 'primary_color') {
					this.primaryColor = this.embed[key]
				}
			}
		},
		onSecondaryColorInput(v) {
			this.form.secondary_color = v
			this.onSubmit()
		},
		onPrimaryColorInput(v) {
			this.form.primary_color = v
			this.primaryColor = v
			this.onSubmit()
		},
		initLoadingEmbedObserver() {
			const targetNode = document.body
			const config = {
				attributes: true,
				childList: true,
				subtree: true
			}
			const vm = this
			const observer = new MutationObserver(function() {
				let parent = document.querySelector('roundup-donation-embed')
				if (parent && parent.childElementCount > 0) {
					vm.loading = false
				}
			})
			observer.observe(targetNode, config)
			this.loadingEmbedObserver = observer
		},
		updateEmbedAttributes() {
			const el = document.querySelector('roundup-donation-embed')
			el.setAttribute('primarycolor', this.form.primary_color)
			el.setAttribute('secondarycolor', this.form.secondary_color)
			el.setAttribute('hidecustominput', !this.form.display_other_amount)
			el.setAttribute('showtopbar', this.form.show_header)
			el.setAttribute(
				'options',
				`[
				${this.form.donation_option_1},
				${this.form.donation_option_2},
				${this.form.donation_option_3}
			]`
			)
			el.setAttribute('type', 'nonprofit')
		},
		async init() {
			this.loading = true
			const params = getUrlParams(window.location.href)
			if (!params.norefresh) {
				window.location.href = window.location.href + '?norefresh=true'
				window.location.reload()
			}
			if (!this.activeOrgUUID) {
				await this.$store.commit(
					'EMBED_DETAIL_SET_ACTIVE_ORG_UUID',
					this.merchant.institution.uuid
				)
			}

			this.selectedInstitution = this.institutionList.list
				.map(e => e.institution)
				.find(e => e.uuid === this.merchant.institution.uuid)

			await this.getEmbedForm()
			if (this.embed) {
				for (var key in this.$data.form) {
					this.$data.form[key] = this.embed[key]
					if (key === 'primary_color') {
						this.primaryColor = this.embed[key]
					}
				}
			}
			this.initLoadingEmbedObserver()
			var script = document.createElement('script')
			const vueAppFormURL =
				process.env.VUE_APP_EMBED_FORM_URL ||
				'https://s3.amazonaws.com/embed.roundupapp.com/dev/donation/js/roundup-donation-embed.js'

			script.setAttribute('src', vueAppFormURL)
			this.script = script

			document.body.appendChild(script)
			const vm = this
			setTimeout(() => {
				if (vm.loading) {
					vm.loadingText =
						'Argh! Something went wrong. Please refresh your browser window to see embed.'
				}
			}, 5000)
		}
	},

	watch: {
		merchant: {
			function(newV) {
				if (newV.institution) {
					this.showNoNonprofitMessage = false
				}
			},
			deep: true
		}
	},

	async mounted() {
		if (this.merchant && this.merchant.institution) {
			await this.listMyInstitutions()
			await this.init()
		} else {
			this.showNoNonprofitMessage = true
		}
	},

	beforeDestroy() {
		this.script.remove()
		if (this.loadingEmbedObserver) {
			this.loadingEmbedObserver.disconnect()
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.donation-form::v-deep .v-tabs-slider-wrapper {
	display: none !important;
}

.donation-form::v-deep .v-application--wrap {
	justify-content: unset;
	align-items: unset;
}

.donation-form::v-deep .v-tab {
	border-bottom: 0px !important;
}

.donation-form::v-deep .v-tabs-bar__content {
	.v-tab {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		font-family: 'Work Sans', sans-serif !important;
		font-size: 16px !important;
		font-weight: 600 !important;
	}
	.v-tab::before {
		opacity: 0.12 !important;
		color: #747a8d !important;
	}

	.v-tab--active::before {
		opacity: 0 !important;
	}
	.v-tab:nth-child(2) {
		border-right: 0px !important;
	}
}
</style>
